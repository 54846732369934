// import store from '@/store/index'

export default [
  {
    title: 'Disposisi',
    route: 'disposisi',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Perintah',
    route: 'perintah',
    icon: 'AlertCircleIcon',
  },
  {
    title: 'Surat Keluar',
    route: 'suratKeluar',
    icon: 'MailIcon',
  },

  {
    title: 'Permintaan Data',
    route: 'permintaanData',
    icon: 'FileIcon',
  },
  {
    title: 'Keluhan',
    route: 'Permintaan Data',
    icon: 'FeatherIcon',
    children: [
      {
        title: 'Keluhan Internal',
        route: 'keluhanInternal',
      },
      {
        title: 'Keluhan External',
        route: 'keluhanExternal',
      },
    ],
  },
  {
    title: 'Arsip',
    route: 'arsip',
    icon: 'SaveIcon',
  },
  {
    title: 'Security',
    route: 'security',
    icon: 'LockIcon',
  },
  {
    title: 'Agenda',
    route: 'agenda',
    icon: 'CalendarIcon',
  },

  {
    title: 'Log Kendaraan',
    route: 'kendaraan',
    icon: 'TruckIcon',
  },

  {
    title: 'Kejadian Penting',
    route: 'kejadian',
    icon: 'BookmarkIcon',
  },

  {
    title: 'Tanggap Darurat',
    route: 'tanggap-darurat',
    icon: 'ZapIcon',
  },

  {
    title: 'Manajemen Akun',
    route: 'user',
    icon: 'UserIcon',
  },
]
